<template>
  <div class="community-promote">
    <div class="tab-bar">
      <div class="user-box">
        <div class="user-avatar">
          <div class="user-img">
            <img src="../../../../../assets/images/user_img.png" alt="" />
          </div>
        </div>
      </div>
      <div class="menu">
        <div class="menu-item current">
          <i class="iconfont">&#xe730;</i>
        </div>
      </div>
    </div>
    <div class="contact-bar">
      <div class="user-info">
        <el-input class="searchUser-input" clearable>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="">
          <i class="iconfont">&#xe752;</i>
        </div>
      </div>
      <div class="user-container">
        <el-scrollbar class="user-content" style="height: 100%;">
          <!-- <div class="levelTwo-item" v-for="(promoteItem, promoteIndex) in promoteList"
                         :key="`promoteList${promoteIndex}`"> -->
          <div class="levelTwo-item">
            <div class="user-avatar">
              <img
                class="avatar"
                :src="user_img"
                alt=""
              />
            </div>
            <div class="content">
              <div class="top">
                <!-- <div class="text-overflow name">{{promoteItem}}</div> -->
                <div
                  v-if="
                    this.group_chat_name == null || this.group_chat_name == ''
                  "
                  class="text-overflow name"
                >
                  {{ wx_group_name }}
                </div>
                <div v-else class="text-overflow name">
                  {{ group_chat_name }}
                </div>
                <div class="wait-time">{{ nowDate }}</div>
              </div>
              <div class="bottom">
                <div class="text-overflow message">{{ chatContent}}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="main-container">
      <div class="main-top">
        <div class="user-info" @click="drawer = true">
          <div
            v-if="
              (this.group_chat_name == null || this.group_chat_name == '') &&
              (this.mark == null || this.mark == '')
            "
            class="text-overflow user-name"
          >
            {{ wx_group_name }}
          </div>
          <!-- <div v-else class="text-overflow user-name">
            <div></div>
          </div>
           -->
          <div v-if="!mark" class="user-name">
            <div class="text-overflow">
              {{ group_chat_name }}
            </div>
          </div>
          <div v-else>
            <div class="user-name">
              <div class="user-name-mark">
                {{ mark }}
              </div>
              <div class="user-name-mark">({{ wx_group_people_count }})</div>
            </div>
            <div class="group-chat-name">{{ group_chat_name || "群聊" }}</div>
          </div>
        </div>
        <div class="more-icon" @click="drawer = true">
          <i class="iconfont">&#xe708;</i>
        </div>
      </div>
      <el-scrollbar class="chat-container" ref="chatContainer">
        <div class="chat-content">
          <div
            class="chat-item current"
            v-for="(item, index) in record"
            :key="index"
          >
            <div class="chat-user">
              <img
                v-if="item.type==2"
                :src="item.avatar"
                alt=""
              />
            </div>
            <div class="chat-message">
              <div class="time">{{ item.time }}</div>
              <div class="edit-text" v-if="item.type==1">{{ item.content}}</div>
              <!-- <div class="line" v-if="item.content">
                <div class="text-overflow">{{ item.mark }}</div>
                ../../../../../assets/images/user_img.png
              </div> -->
              <div class="message-wrapper" v-if="item.type==2">
                <div class="message-box">
                  {{ item.content}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="send-message">
        <div class="default-emoji">
          <div class="emoji-item">
            <i class="iconfont" @click="toogleDialogEmoji">&#xe648;</i>
            <VEmojiPicker
              class="emoji"
              v-show="showDialog"
              :style="{ width: '20%', 'max-height': '500px','min-width':'200px' }"
              labelSearch="Search"
              lang="pt-BR"
              @select="onSelectEmoji"
            />
          </div>
          <!-- 输入框 -->
        </div>
        <div class="input-box">
          <el-input
            class="send-message-input"
            v-model="sendMessage"
            type="textarea"
            resize="none"
            placeholder="请输入..."
            @change="sendEmojip"
            maxlength="200"
            @keyup.enter.native="sentInput"
            @keydown.enter.prevent
          ></el-input>
        </div>
        <div class="send-bottom">
          <el-button class="send-button" size="medium" @click="sendInputMessage"  
            >发送/enter</el-button
          >
        </div>
      </div>
    </div>
    <!--侧边消息-->
    <el-drawer  :visible.sync="drawer" :with-header="false" :size="300">
      <div class="more-container">
        <div class="more-top">
          <!-- <label>{{group_chat_name}}</label> -->
          <div class="group_lable">
            <label>群聊名称</label>
          <div class="text" v-show="!editGroupName" @click="editGroupName1">
            {{ group_chat_name || "点击编辑群名" }}
          </div>
          <div v-show="editGroupName" class="edit-input">
            <el-input
              v-model="group_chat_name"
              type="text"
              maxlength="15"
              placeholder="请输入1-15个字"
              @keydown.enter="saveGroupName($event)"
              @blur="saveGroupName($event)"
              @change="isChange()"
              ref="Textinp1" 
            />
          </div>
          </div>
          <!-- <div v-else @dblclick="proEdit($event)">双击编辑群名称</div> -->
          <div class="group_lable">
            <label>备注</label>
          <div class="text" v-show="!editGroupMark" @click="editGroupMark1">
            {{ mark || "点击编辑备注" }}
          </div>
          <div v-show="editGroupMark" class="edit-input">
            <el-input
              type="text"
              v-model="mark"
              maxlength="15"
              placeholder="请输入1-15个字"
              @keydown.enter="saveGroupMark($event)"
              @blur="saveGroupMark($event)"
              @change="isRemarkChange()"
              ref="Textinp2" 
            />
          </div>
          </div>


          <!-- <div v-else @dblclick="markEdit($event)">群聊备注仅自己可见</div> -->
          <!-- <label>{{group_notice}}</label> -->
          <label>公告</label>
          <!-- <el-tooltip
            content="点击编辑群公告"
            placement="bottom-start"
            effect="light"
          > -->
            <div
              class="edit-group edit-text"
              v-if="this.group_notice == null || this.group_notice == ''"
              @click="editGroup"
            >
              点击编辑群公告
            </div>
            <div class="edit-group " v-else @click="editGroup">
              {{ group_notice }}
            </div>
          <!-- </el-tooltip> -->
        </div>
       <div class="userinfo-content">
        <el-scrollbar>
          <div
            class="levelTwo-item"
            v-for="(promoteItem, promoteIndex) in promoteList"
            :key="`promoteList${promoteIndex}`"
          >
            <div class="user-avatar">
              <img
                class="avatar"
                :src="promoteItem.avatar"
                alt=""
              />
            </div>
            <div class="content">
              <div class="top">
                <div class="text-overflow name" style="margin-left: 10px">
                  {{ promoteItem.name }}
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
       </div>
        <div class="more-bottom" @click="retreatGroup">退出群聊</div>
      </div>
    </el-drawer>
    <!--群公告弹窗-->
    <el-dialog
      title="群公告"
      :visible.sync="groupAnnouncementDialog"
      width="350px"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span>群公告</span>
      </div>
      <el-input
        class="text-input"
        type="textarea"
        v-model="groupText"
        placeholder="请输入1-2000个字"
        resize="none"
        show-word-limit
        maxlength="2000"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="cancel"
          size="medium"
          @click="groupAnnouncementDialog = false"
          >取 消</el-button
        >
        <el-button
          class="confirm"
          size="medium"
          :type="this.groupText ? 'success' : ''"
          @click="groupTextBtn"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  groupPromotionStuEaxmGroup,
  grouppromotionGetGroupInfo,
  grouppromotionStuGroup,
  grouppromotionGetGroupMsg,
  grouppromotionSaveGroupMsg
} from "@/utils/apis";

import { VEmojiPicker, emojisDefault, categoriesDefault } from "v-emoji-picker";
export default {
  name: "CommunityPromote",
  data() {
    return {
      searchUser: "",
      promoteList: [],
      wx_group_name: "",
      mark: "",
      group_notice: "",
      group_chat_name: "",
      wx_group_people_count: "",
      groupAnnouncementDialog: false, //群公告弹窗
      groupText: "",
      drawer: false,
      nowDate: "", //获取当前时间
      isEditGroup: false,
      isEditGroupName: false,
      isEditGroupMark: false,
      record: [],
      editID: "", //编辑
      editGroupName: false,
      editGroupMark: false,
      isChnage: false,
      isRemarkChange:false,
      sendMessage: "",
      showDialog: false,
      chatContent: "",
      user_img:'',
      pageForm:{
        page:1,
        limit:20,
        paging:0
      }
    };
  },
  mounted() {
    // this.getStuEaxmGroup()
    this.getGroupInfo();
    let now = new Date();
    let hour = now.getHours();
    let minute = now.getMinutes();
    this.nowDate = hour + ":" + minute;
    this.getGroupMsg()
  },
  methods: {
    getGroupInfo() {
      let params = {};
      if (this.$route.query.op_id) {
        params.type = "1";
        params.op_id = this.$route.query.op_id; // 实操题试题id
        params.course_id = this.$route.query.course_id; // 课程id
      } else {
        params.type = "2";
        params.eaxm_id = this.$route.query.eaxm_id;
      }
      grouppromotionGetGroupInfo(params)
        .then((res) => {
          // console.log(res, '221234')
          // console.log(res, this.editID)
          this.wx_group_name = res.data.wx_group_name;
          this.wx_group_people_count = res.data.wx_group_people_count;
          // this.promoteList = res.data.groups;
          this.promoteList =res.data.wx_group_member
          this.group_chat_name = res.data.group_chat_name;
          this.mark = res.data.mark;
          this.group_notice = res.data.group_notice;
          this.groupText = this.group_notice;
          this.editID = res.data.id;
          this.user_img=res.data.wx_group_avatar
        })
        .catch((err) => {});
    },
    //侧边群名编辑
    proEdit(e) {
      this.isEditGroupName = true;
    },
    //编辑群名
    saveGroupName(e) {
      this.editGroupName = false;
      this.editGroupMark=false
      if (this.isChnage) {
        this.stuExamGroup(2, e.target.value);
        this.isChnage = false;
      }
    },
    editGroupName1(){
      this.editGroupMark = false; 
      this.editGroupName = true; 
      this.$nextTick(() => {
      this.$refs.Textinp1.focus()      
})
    },
    editGroupMark1(){
      this.editGroupMark = true; 
      this.editGroupName = false; 
      this.$nextTick(() => {
      this.$refs.Textinp2.focus()      
})
    },
    //公告确定按钮
    groupTextBtn() {
      this.stuExamGroup(3);
    },
    //备注
    saveGroupMark(e) {
      this.editGroupMark = false;
      this.editGroupName = false;
      if(this.isRemarkChange){
        this.mark = e.target.value;
        this.$nextTick(() => {
        this.stuExamGroup(1, e.target.value);
        this.isRemarkChange=false
    })
      }
    },
    stuExamGroup(type, value) {
      let params = {
        group_chat_name: this.group_chat_name,
        mark: this.mark,
        // group_notice: this.group_notice,
        group_notice: this.groupText,
      };
      if (this.$route.query.op_id) {
        params.op_id = this.$route.query.op_id;
        params.course_id = this.$route.query.course_id;
        if (this.editID) {
          params.id = this.editID;
        }
        grouppromotionStuGroup(params)
          .then((res) => {
            if (type === 1) {
              this.isEditGroup = false;
            } else {
              let t = new Date();
              let h = t.getHours() > 9 ? t.getHours() : "0" + t.getHours();
              let m =
                t.getMinutes() > 9 ? t.getMinutes() : "0" + t.getMinutes();
              let ob = {
                time: h + ":" + m,
              };
              if (type === 2) {
                // 2 群聊备注
                // this.group_chat_name = value;
                this.isEditGroupName = false;
                ob["text"] = '你修改群名为"' + this.group_chat_name + '"';
                let params={
                    type:1,
                    content:'你修改群名为"' + this.group_chat_name + '"'
                }
                grouppromotionSaveGroupMsg(params).then(res=>{
                      this.getGroupMsg()})
              } else {
                // 3群公告
                ob["group_chat_name"] = this.group_chat_name;
                ob["mark"] = this.mark;
                ob["content"] = "@所有人 " + this.groupText;
                this.group_notice = this.groupText;
                this.groupAnnouncementDialog = false;
                let params={
                      type:2,
                       content:"@所有人 " + this.groupText
                }
                grouppromotionSaveGroupMsg(params).then(res=>{
                  this.getGroupMsg()})
              }   
            }
          }).catch((err) => {});
      } else {
        params.eaxm_id = this.$route.query.eaxm_id;
        if (this.editID) {
          params.id = this.editID;
        }
        groupPromotionStuEaxmGroup(params)
          .then((res) => {
            // this.promoteList = res.data
            // console.log(res, "zong");
            if (type === 1) {
              // this.group_chat_name = value;
              this.isEditGroup = false;
            } else {
              let t = new Date();
              let h = t.getHours() > 9 ? t.getHours() : "0" + t.getHours();
              let m =
                t.getMinutes() > 9 ? t.getMinutes() : "0" + t.getMinutes();
              let ob = {
                time: h + ":" + m,
              };
              if (type === 2) {
                this.group_chat_name = value;
                this.isEditGroupName = false;
                ob["text"] = '你修改群名为"' + this.group_chat_name + '"';
                let params={
                       type:1,
                        content:'你修改群名为"' + this.group_chat_name + '"'
                    }
      grouppromotionSaveGroupMsg(params).then(res=>{
        // console.log(res);
        // console.log("提交成功");
        this.getGroupMsg()
      })
              } else {
                ob["group_chat_name"] = this.group_chat_name;
                // ob["mark"] = this.mark;
                ob["content"] = "@所有人 " + this.groupText;

                this.group_notice = this.groupText;
                this.groupAnnouncementDialog = false;
                let params={
        type:2,
        content:"@所有人 " + this.groupText
      }
      grouppromotionSaveGroupMsg(params).then(res=>{
        this.getGroupMsg()
      })
              }
            }
          })
          .catch((err) => {});
      }
    },
    isChange() {
      this.isChnage = true;
    },
    isRemarkChange(){
      this.isRemarkChange=true
    },
    //点击编辑群公告
    editGroup() {
      this.groupAnnouncementDialog = true;
    },
    //退出群聊
    retreatGroup() {
      this.$confirm(
        "退出后不会通知群聊中其他成员，且不会在接收次群聊消息？",
        "退出群聊提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showClose: false,
        }
      )
        .then(() => {
          this.$message.warning("无法退出");
        })
        .catch(() => {
          this.$message.info("已取消退出");
        });
    },
    // 发送消息
    sendInputMessage() {
      if (this.sendMessage.trim()=='') {
        this.$message.warning("输入内容不能为空");
      }else{
        let t = new Date();
      let h = t.getHours() > 9 ? t.getHours() : "0" + t.getHours();
      let m = t.getMinutes() > 9 ? t.getMinutes() : "0" + t.getMinutes();
      let ob = {
        time: h + ":" + m,
      };
      ob["group_chat_name"] = this.group_chat_name;
      // ob["mark"] = this.mark;
      ob["content"] = this.sendMessage;
      this.group_notice = this.groupText;
      // console.log(this.sendMessage);
      let params={
        type:2,
        content:this.sendMessage
      }
      grouppromotionSaveGroupMsg(params).then(res=>{
        // console.log(res);
        // console.log("提交成功");
        this.getGroupMsg()
      })
      // this.record.push(ob);
      this.chatContent=this.sendMessage
      this.sendMessage = "";
      this.showDialog = false;
     
      //
      
   
      }
     
    },
    sentInput(event){  
	if (event.key === 'Enter') this.sendInputMessage();

    },
    toogleDialogEmoji() {
      this.showDialog = !this.showDialog;
    },
    onSelectEmoji(emoji) {
      this.sendMessage += emoji.data;
      this.showDialog=false
      // Optional
      // this.toogleDialogEmoji();
    },
    sendEmojip() {
      // this.showDialog=false
    },
    getGroupMsg(){
      grouppromotionGetGroupMsg(this.pageForm).then(res=>{
        if(res.code==200){
          this.record=res.data.list
          this.scrollToBottom()
        }
      })

    },
    scrollToBottom() {
      this.$nextTick(() => {
        const element = document.querySelector('.chat-content')
        const height = element.scrollHeight
        this.$refs['chatContainer'].wrap.scrollTop = height
      });
    }
  },
  components: {
    VEmojiPicker,
  },
};
</script>

<style scoped lang="scss">
// .chat-container {
//   height: 300px; /* 设置聊天容器的高度，保持滚动条可见 */
//   overflow-y: auto; /* 当消息内容超出容器高度时显示滚动条 */
// }
.community-promote {
  display: flex;
  /*width: 100vw;*/
  height: 100vh;
  overflow: hidden;
  .tab-bar {
    width: 60px;
    color: #fff;
    background: #2a2a2c;
    display: flex;
    flex-direction: column;
    .user-box {
      height: 80px;
      display: flex;
      .user-avatar {
        display: flex;
        margin: auto;
        position: relative;
        .user-img {
          width: 34px;
          height: 34px;
          border: 2px solid #fff;
          border-radius: 2px;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .menu {
    flex: 1;
    height: 1%;
    .menu-item {
      width: 100%;
      line-height: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .iconfont {
        font-size: 22px;
      }
      .text {
        font-size: 16px;
        margin-top: 10px;
      }
      &.current {
        color: #07c160;
      }
    }
  }
  .contact-bar {
    width: 250px;
    display: flex;
    flex-direction: column;
    background: #f5f8ff;
    .user-info {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #e4e1e1;
    }
    .user-container {
      height: 1%;
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #eaeaea;
      .user-content {
        // height: 500px;
        // height: 1%;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
          max-height: 730px;
        }
        :deep(.el-scrollbar__wrap){
          overflow-x: hidden;
          max-height: 730px;
        }
      
        .levelOne-item {
          display: flex;
          cursor: pointer;
          padding: 0 15px;
          line-height: 40px;
          .iconfont {
            color: #bfbfbf;
          }
          .text {
            width: 1%;
            flex: 1;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }
    }
    .searchUser-input {
      ::v-deep {
        .el-input__inner {
          width: 96%;
          height: 24px;
          line-height: 24px;
          color: #fff;
          font-size: 12px;
          border-color: #dbd9d8;
          background-color: #dbd9d8;
        }
        .el-input__prefix {
          color: #666666;
        }
        .el-input__icon {
          line-height: 24px;
          &.el-icon-search {
            cursor: pointer;
          }
        }
      }
    }
    .content {
      width: 1%;
      flex: 1;
      color: #666;
      font-size: 12px;
      margin-left: 10px;
      .top {
        display: flex;
        align-items: center;
        .name {
          color: #333;
          font-size: 14px;
          width: 1%;
          flex: 1;
        }
        .wait-time {
          margin-left: 10px;
        }
      }
      .bottom {
        display: flex;
        margin-top: 4px;

        .message {
          flex: 1;
          width: 1%;
        }
      }
    }
  }
  .main-container {
    width: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-top {
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      box-sizing: border-box;
      padding: 0 0 0 20px;
      display: flex;
      align-items: center;
      //   background: #f5f5f5;
      .user-info {
        display: flex;
        width: 1%;
        flex: 1;
      }
      .more-icon {
        cursor: pointer;
        padding-right: 10px;

        i {
          font-size: 22px;
        }
      }
    }
    .chat-container {
      flex: 1;
      height: 1%;
      background: #f0f1f1;
      & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        & > .el-scrollbar__view {
          min-width: 500px;
        }
      }
      .scene-box {
        padding: 12px 20px;
        background: #fff;
        display: flex;
        align-items: center;
        .scene-text {
          width: 1%;
          flex: 1;
        }
        .link-blue-shadow {
          margin-left: 40px;
        }
      }
      .chat-content {
        padding: 12px 20px 0;
        .chat-item {
          display: flex;
          margin-bottom: 24px;
          .chat-user {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 2px;
            overflow: hidden;
            margin-top: 36px;
            img {
              max-width: 100%;
            }
          }
          .chat-message {
            width: 1%;
            flex: 1;
            margin-left: 14px;
            .time {
              color: #fff;
              font-size: 12px;
              padding: 4px 0;
              background: #dadada;
              border-radius: 2px;
              text-align: center;
              width: 68px;
              margin: 0 auto;
            }
            .edit-text {
              text-align: center;
              margin-top: 4px;
              font-size: 14px;
              color: #999;          
            }
            .line {
              color: #999;
              line-height: 1;
              display: flex;

              .text-overflow {
                max-width: 50%;
              }
            }
            .message-wrapper {
              display: flex;
              align-items: flex-end;
            }
            .message-box {
              margin-top: 2px;
              background: #98e165;
              padding: 10px 10px;
              border-radius: 4px;
              display: inline-block;
              position: relative;
              line-height: 20px;
              max-width: 50%;
              box-sizing: border-box;
              &:before {
                content: "";
                position: absolute;
                top: 14px;
                left: -6px;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid #98e165;
              }
            }
          }
          &.current {
            flex-direction: row-reverse;
            text-align: right;
            .chat-message {
              margin-left: 0;
              margin-right: 14px;
              text-align: right;
              .line {
                flex-direction: row-reverse;
              }
            }
            .message-wrapper {
              flex-direction: row-reverse;
            }
            .message-box {
              background: #98e165;
              text-align: left;
              margin-top: 10px;
              word-wrap: break-word;

              &:before {
                content: none;
              }
              //   &:after {
              //     content: "";
              //     position: absolute;
              //     top: 14px;
              //     right: -6px;
              //     width: 0;
              //     height: 0;
              //     border-top: 6px solid transparent;
              //     border-bottom: 6px solid transparent;
              //     border-left: 6px solid #98e165;
              //   }
            }
          }
        }
      }
    }
    .send-message {
      height: 220px;
      .default-emoji {
        height: 40px;
        border-top: 1px solid #eee;
        .emoji-item {
          padding: 0 4px;
          position: relative;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
      }
    }
  }
  .more-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .userinfo-content{
      height:calc(100vh - 251px);
      .el-scrollbar{
        height: 100%;
        overflow-y: auto;
      }
    }
    .more-bottom {
    height: 52px;
    width: 100%;
    text-align: center;
    line-height: 52px;
    background: #fff;
    // padding: 10px 0;
    color: #ff0000;
    border-top: 1px solid #eee;
    cursor: pointer;
  }
    .more-top {
      padding: 20px 20px 0;
      box-sizing: border-box;
     
      label {
        font-size: 14px;
        // color: #999;
        color: #000000 ;
      }
      div {
        margin-bottom: 10px;
      }
      .edit-input {
        input {
          width: 200px;
          height: 24px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
        }
      }
    }
    .edit-group {
      cursor: pointer;
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      overflow: hidden; /* 超出部分隐藏 */
      white-space: nowrap; /* 不换行 */
      text-overflow: ellipsis;
      color: #7F7F7F;
      margin-top: 5px;
      font-size: 14px;
      
    }
  }
  .levelTwo-item {
    height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px 0 25px;
    transition: all 0.3s;
    cursor: pointer;
    // border-bottom: 1px solid #eee;
    .user-avatar {
      width: 34px;
      height: 34px;
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
      .avatar {
        filter: grayscale(100%);
        &.current {
          filter: grayscale(0);
        }
      }
    }
  }

  .dialog-footer {
    text-align: center;
    .text-input {
      width: 100%;
      height: 80px;
    }
    .el-button {
      border: 1px solid #2dc079;
      border-radius: 4px;
      text-align: center;
      color: #2dc079;
    }
    .cancel {
      border: 1px solid #ccc;
      color: #555;
    }
    .confirm {
      background-color: rgb(28, 176, 100);
      color: #fff;
    }
  }
  em {
    font-style: normal;
  }
}

::v-deep .el-message-box__btns {
  text-align: center !important;
}
::v-deep .el-drawer rtl {
  width: 20% !important;
}
::v-deep .el-drawer__body{
  width: 300px;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  // max-height: 760px;
  height: 100%;
}
// ::v-deep .el-textarea__inner:focus{
//     border: none;
// }
::v-deep .el-textarea__inner {
  border: none;
  height: 260px;
  border-top: 1px solid #999;
  border-radius: 0px;
}
::v-deep .el-dialog__body {
  padding: 0 20px !important;
}
.dialog-header-title {
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
}
.send-message {
  display: flex;
  flex-direction: column;
}
.input-box {
  width: 100%;
  ::v-deep .el-textarea__inner {
    border: none;
    height: 140px;
    border-radius: 0px;
    font-size: 16px;
  }
}
.send-message-input {
}
.send-bottom {
  display: flex;
  justify-content: flex-end;
}
.send-button {
  background-color: rgb(28, 176, 100);
  color: #fff;
  margin: 10px;
}
.user-name {
  display: flex;
  //   justify-content: center;
  .user-name-mark {
    font-size: 16px;
  }
}
.group-chat-name {
  color: #666666;
  font-size: 12px;
}
.emoji {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 100;
  transform: translate(-50%,0);
}
::v-deep .el-input__inner{
  width: 200px;
  height: 30px;
}
.group_lable{
  height: 40px;
  font-size: 14px;
  .text{
    line-height: 26px;
    color: #7F7F7F ;
  }
}
.edit-text{
  padding-top: 5px;
}
</style>